//
// Form styles
// =============================================================================

$input_height: 45px;

input[type="text"],
input[type="email"],
textarea {
	border: 0;
	border-radius: 0;
	box-shadow: none;
	font-size: 14px;
	height: $input_height;
	padding: 0 15px;
	color: $black;
	font-family: $noto_serif;
	font-weight: normal;
	transition: $transition-duration;
	width: 100%;
	line-height: normal;
	min-height: $input_height;

	&:focus {
		outline: none;
		border-color: $color-primary;
	}

	&::-webkit-input-placeholder {
		color: lighten($black, 40%);
	}

	&::-moz-placeholder { /* Firefox 19+ */
		color: lighten($black, 40%);
	}

	&:-ms-input-placeholder { /* IE 10+ */
		color: lighten($black, 40%);
	}

	&:-moz-placeholder { /* Firefox 18- */
		color: lighten($black, 40%);
	}

	.form__field--error & {
		border: 2px solid $red;
	}

}

fieldset {
	border: 0;
	border-radius: 0;
	padding: 0;
	margin-bottom: 0;
}

textarea {
	height: auto;
	padding: 15px;
	resize: vertical;
	width: 100%;
	min-height: 150px;
}

.form {
	margin-bottom: 60px;

	&__field-error {
		color: $red;
		font-family: $intro;
		font-size: 11px;
		letter-spacing: 0.5px;
		margin-bottom: 20px;
	}

	&__row {
		width: 100%;

		@include breakpoint($bp-extra-small) {
			display: flex;
			flex-direction: row;
		}

		.form__field {
			flex: 1;
			margin-bottom: 20px;
		}

		&--2-cols {

			.form__field {

				@include breakpoint($bp-extra-small) {
					margin-right: 20px;
				}

				&--last {
					margin-right: 0;
				}

			}

		}

	}

	&__submit {

		.button {
			display: block;
			width: 100%;

			@include breakpoint($bp-extra-small) {
				display: inline-block;
				width: auto;
			}

		}

	}

}

// Custom checkbox

.newsletter  {
	position: relative;

	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		z-index: 1;
	}

	label {
	    position: relative;
		z-index: 2;
	    display: inline-block;
		font-family: $intro;
		font-size: 11px;
		height: 18px;
		line-height: 18px;
	    padding-left: 28px; /* 16px width of fake checkbox + 6px distance between fake checkbox and text */

		@include breakpoint($bp-extra-small) {
			font-size: 13px;
			letter-spacing: 0.5px;
		}

		&:before,
		&:after {
			position: absolute;
		    content: "";
		    display: inline-block; /* Needed for the line-height to take effect */
		}

		/* Outer box of the fake checkbox */
		&:before {
			cursor: pointer;
		    height: 18px;
		    width: 18px;
			background-color: white;
		    left: 0px;
		    top: 0;
		}

		/* Checkmark of the fake checkbox */
		&:after {
		    height: 6px;
		    width: 11px;
		    border-left: 3px solid $black;
		    border-bottom: 3px solid $black;
		    transform: rotate(-45deg);
		    left: 4px;
		    top: 6px;
		}

	}

	/* Hide the checkmark by default */
	input[type="checkbox"] + label::after {
	    content: none;
	}

	/* Unhide on the checked state */
	input[type="checkbox"]:checked + label::after {
	    content: "";
	}

}

.form {

	.sk-folding-cube {
		opacity: 0;
		margin: 20px auto;
		width: 40px;
		height: 40px;
		position: relative;
		-webkit-transform: rotateZ(45deg);
		transform: rotateZ(45deg);
	}

	.sk-folding-cube .sk-cube {
		float: left;
		width: 50%;
		height: 50%;
		position: relative;
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);
	}
	.sk-folding-cube .sk-cube:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #333;
		-webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
		animation: sk-foldCubeAngle 2.4s infinite linear both;
		-webkit-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}
	.sk-folding-cube .sk-cube2 {
		-webkit-transform: scale(1.1) rotateZ(90deg);
		transform: scale(1.1) rotateZ(90deg);
	}
	.sk-folding-cube .sk-cube3 {
		-webkit-transform: scale(1.1) rotateZ(180deg);
		transform: scale(1.1) rotateZ(180deg);
	}
	.sk-folding-cube .sk-cube4 {
		-webkit-transform: scale(1.1) rotateZ(270deg);
		transform: scale(1.1) rotateZ(270deg);
	}
	.sk-folding-cube .sk-cube2:before {
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}
	.sk-folding-cube .sk-cube3:before {
		-webkit-animation-delay: 0.6s;
		animation-delay: 0.6s;
	}
	.sk-folding-cube .sk-cube4:before {
		-webkit-animation-delay: 0.9s;
		animation-delay: 0.9s;
	}
	@-webkit-keyframes sk-foldCubeAngle {
		0%, 10% {
			-webkit-transform: perspective(140px) rotateX(-180deg);
			transform: perspective(140px) rotateX(-180deg);
			opacity: 0;
		} 25%, 75% {
			  -webkit-transform: perspective(140px) rotateX(0deg);
			  transform: perspective(140px) rotateX(0deg);
			  opacity: 1;
		  } 90%, 100% {
				-webkit-transform: perspective(140px) rotateY(180deg);
				transform: perspective(140px) rotateY(180deg);
				opacity: 0;
			}
	}

	@keyframes sk-foldCubeAngle {
		0%, 10% {
			-webkit-transform: perspective(140px) rotateX(-180deg);
			transform: perspective(140px) rotateX(-180deg);
			opacity: 0;
		} 25%, 75% {
			  -webkit-transform: perspective(140px) rotateX(0deg);
			  transform: perspective(140px) rotateX(0deg);
			  opacity: 1;
		  } 90%, 100% {
				-webkit-transform: perspective(140px) rotateY(180deg);
				transform: perspective(140px) rotateY(180deg);
				opacity: 0;
			}
	}

	&--loading {
		.sk-folding-cube {
			opacity: 1;
		}
	}
}
