/* ===========================
   @services
   =========================== */

.services {
	position: relative;
	padding-top: 60px;

	@include breakpoint($bp-extra-small) {
		padding: 80px 12px 12px 12px;
	}

	@include breakpoint($bp-large) {
		padding: 0;
	}

	&:after {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 50%;
		bottom: 0;
		width: 1px;
		height: 100%;
		background-color: $black;
	}

	.service {
		width: 100%;
		position: relative;
		z-index: 2;
		margin: 0 0 80px 0;
		padding: 40px 0 40px 0;

		@include breakpoint($bp-medium) {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			background: none;
			margin: -180px 0 0 0;
			padding: 0;
		}

		&:before {
			content: "";
			position: absolute;
			top: -10px;
			left: 50%;
			width: 20px;
			height: 20px;
			transform: translateX(-50%);
			background-color: white;
			border: 1px solid $black;
			border-radius: 50%;
			z-index: 3;

			@include breakpoint($bp-medium) {
				display: none;
			}

		}

		&:after {
			content: "";
			position: absolute;
			left: 50%;
			width: 10px;
			height: 100%;
			transform: translateX(-50%);
			bottom: 0;
			z-index: 2;
			background: white;

			@include breakpoint($bp-medium) {
				display: none;
			}

		}

		&--first {
			margin-top: 0;
		}

		$timeline_spacing: 80px;

		&__wrap {
			width: 100%;
			padding: 0;
			position: relative;
			z-index: 5;

			@include breakpoint($bp-medium) {
				width: 50%;
			}

		}

		&__content {
			position: relative;
			font-size: 13px;
			text-align: center;

			@include breakpoint($bp-extra-small) {
				font-size: 14px;
			}

			@include breakpoint($bp-medium) {
				text-align: left;
			}

			@include breakpoint($bp-large) {
				font-size: 15px;
			}

			&:before,
			&:after {

				@include breakpoint($bp-medium) {
					content: "";
					position: absolute;
				}

			}

			&:before {

				@include breakpoint($bp-medium) {
					top: 6px;
					right: -10px;
					width: 20px;
					height: 20px;
					background-color: white;
					border: 1px solid $black;
					border-radius: 50%;
					z-index: 3;
				}

			}

			&:after {

				@include breakpoint($bp-medium) {
					top: 16px;
					right: 10px;
					width: 20px;
					height: 1px;
					background-color: $black;
					z-index: 2;
				}

			}

		}

		&__image,
		&__title,
		&__text,
		&__tags {
			display: block;
			margin-left: auto;
			margin-right: auto;
			max-width: 480px;

			@include breakpoint($bp-medium) {
				max-width: 430px;
				padding-right: 60px;
			}

			@include breakpoint($bp-large) {
				padding-right: 0;
			}

		}

		&__image {
			margin-bottom: 20px;
			text-align: center;
			max-width: 180px;

			@include breakpoint($bp-extra-small) {
				max-width: 240px;
			}

			@include breakpoint($bp-large) {
				max-width: 430px;
			}

		}

		&__title {
			font-size: 24px;

			@include breakpoint($bp-large) {
				font-size: 30px;
			}

		}

		&__tags {
			padding-left: 0;
			margin: 0 auto;
			display: block;
			width: 100%;

			li {
				margin: 0;
				padding: 0;
				list-style: none;
				border: 1px solid $black;
				line-height: 1.0;
				padding: 5px 5px 4px 5px;
				display: inline-block;
				margin: 0 2px 4px 0;
				font-family: $intro;
				font-size: 9px;

				@include breakpoint($bp-large) {
					font-size: 10px;
					margin: 0 4px 8px 0;
					padding: 6px 6px 5px 6px;
				}

			}

		}

		&--last {
			margin-bottom: 40px;
		}

		&--align-right {
			justify-content: flex-end;

			.service {

				&__content {

					&:before {
						right: auto;
						left: -10px;
					}

					&:after {
						right: auto;
						left: 10px;
					}

				}

				&__image,
				&__title,
				&__text,
				&__tags {

					@include breakpoint($bp-medium) {
						padding-right: 0;
						padding-left: 60px;
					}

					@include breakpoint($bp-large) {
						padding-left: 0;
					}

				}

			}

		}

	}

}
