/* ===========================
   @icons
   =========================== */

@font-face {
 font-family: 'chapterzero';
 src: url('../fonts/chapterzero.eot?90765572');
 src: url('../fonts/chapterzero.eot?90765572#iefix') format('embedded-opentype'),
      url('../fonts/chapterzero.woff2?90765572') format('woff2'),
      url('../fonts/chapterzero.woff?90765572') format('woff'),
      url('../fonts/chapterzero.ttf?90765572') format('truetype'),
      url('../fonts/chapterzero.svg?90765572#chapterzero') format('svg');
 font-weight: normal;
 font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
 @font-face {
   font-family: 'chapterzero';
   src: url('../font/chapterzero.svg?90765572#chapterzero') format('svg');
 }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
 font-family: "chapterzero";
 font-style: normal;
 font-weight: normal;
 speak: none;

 display: inline-block;
 text-decoration: inherit;
 width: 1em;
 margin-right: .2em;
 text-align: center;
 /* opacity: .8; */

 /* For safety - reset parent styles, that can break glyph codes*/
 font-variant: normal;
 text-transform: none;

 /* fix buttons height, for twitter bootstrap */
 line-height: 1em;

 /* Animation center compensation - margins should be symmetric */
 /* remove if not needed */
 margin-left: .2em;

 /* you can be more comfortable with increased icons size */
 /* font-size: 120%; */

 /* Font smoothing. That was taken from TWBS */
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;

 /* Uncomment for 3D effect */
 /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-check:before { content: '\e800'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-github:before { content: '\f113'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-dribbble:before { content: '\f17d'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
