/* ===========================
   @intro
   =========================== */

.intro {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: 140px 0 140px 0;
	align-items: center;
	text-align: center;
	position: relative;

	@include breakpoint($bp-large) {
		padding: 240px 0 200px 0;
	}

	// &__title {
	// 	max-width: 640px;
	// 	display: inline-block;
	// 	margin-left: auto;
	// 	margin-right: auto;
	// 	margin-bottom: 90px;
	// 	padding: 0 20px;
	// 	position: relative;
    //
	// 	@include breakpoint($bp-large) {
	// 		max-width: 820px;
	// 	}
    //
	// 	&:after {
	// 		content: "";
	// 		width: 38px;
	// 		height: 16px;
	// 		background: url('../images/ico-spacer.svg');
	// 		background-size: 38px 16px;
	// 		position: absolute;
	// 		bottom: -50px;
	// 		left: 50%;
	// 		transform: translateX(-50%);
	// 		z-index: 1;
	// 	}
    //
	// }

	&__header {
		color: black;
		font-weight: normal;
		font-size: 80px;
		line-height: 1.0;
		font-family: $sign_painter;
		margin: 0 0 20px 0;
		padding: 0;

		@include breakpoint($bp-medium) {
			font-size: 100px;
		}

		@include breakpoint($bp-extra-large) {
			font-size: 150px;
		}

		span {
		    font-size: 40px;
			font-family: $intro;
		    display: block;
			margin: 0 auto;
		    position: relative;

			@include breakpoint($bp-medium) {
				font-size: 60px;
				display: inline-block;
			}

			@include breakpoint($bp-extra-large) {
				font-size: 100px;
			}

		}

	}

	&__subtitle {
		font-family: $intro;
		font-weight: normal;
		font-size: 20px;
		padding: 0 20px;
		line-height: 1.2;
		margin-bottom: 30px;

		@include breakpoint($bp-extra-small) {
			font-size: 22px;
			line-height: 1.0;
			padding: 0;
			margin-bottom: 40px;
		}

		@include breakpoint($bp-small) {
			font-size: 25px;
		}

	}

	&__text {
		max-width: 540px;
		font-size: 15px;
		display: inline-block;
		margin-left: auto;
		margin-right: auto;

		@include breakpoint($bp-extra-small) {
			font-size: 16px;
		}

		@include breakpoint($bp-large) {
			max-width: 700px;
			font-size: 18px;
		}

	}

}

// .bg-intro-left,
// .bg-intro-right {
// 	position: absolute;
// 	z-index: 1;
// 	width: 100%;
// 	height: auto;
// 	display: block;
// 	top: 0;
// }
//
// .bg-intro-left {
// 	left: 0;
// 	z-index: 3;
// }
//
// .bg-intro-right {
// 	right: 0;
// }
//
// .bg-intro-visual {
// 	display: none;
//
// 	@include breakpoint($bp-small) {
// 		display: block;
// 		position: absolute;
// 		top: 100px;
// 		right: 5%;
// 		z-index: 2;
// 		display: block;
// 		width: 400px;
// 		height: 430px;
// 	}
//
// 	@include breakpoint($bp-large) {
// 		width: 742px;
// 		height: 796px;
// 	}
//
// }
