//
// Buttons
// =============================================================================

.button {
	font-family: $intro;
	background-color: $black;
	border: none;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	line-height: 50px;
	padding: 0 24px;
	box-shadow: 0px 3px 0px rgba(0,0,0,0.15);
	font-weight: normal;
	letter-spacing: 0.5px;
	border-radius: 0;
	text-decoration: none;
	transition: all $tempo ease;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;

	&:hover,
	&:focus {
		// color: $black;
		// background-color: $green;
		text-decoration: none;
		color: $yellow;
		transform: translateY(-4px);
	}

	&:focus,
	&:active {
		outline: none;
	}

	// styles

	&--yellow {
		color: $black;
		background-color: $yellow;

		&:hover {
			color: $black;
		}

	}

}
