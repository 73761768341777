/* ===========================
   @navigation-overlay
   =========================== */

.navigation-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $black;
	opacity: 0;
	visibility: hidden;
	transition: opacity $tempo ease, visibility $tempo ease;

	&--active {
		opacity: 1;
		visibility: visible;
	}

	@include breakpoint($bp-large) {
		display: none;
	}

	ul {
		padding: 60px 0 0 0;
		margin: 0;

		li {
			padding: 15px 0;
			margin: 0;
			list-style: none;
			display: block;

			a {
				color: white;
				display: inline-block;
				padding: 0;
				font-size: 24px;

				@include breakpoint($bp-extra-small) {
					font-size: 32px;
				}

			}

			&.active {

				> a {
					border-bottom: 2px solid $yellow;
				}

			}

		}

	}

}
