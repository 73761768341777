/* ===========================
   @base
   =========================== */

*,
::after,
::before {
	box-sizing: inherit;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	text-decoration: inherit;
	vertical-align: inherit;
}

* {
	cursor: inherit;
	margin: 0;
	padding: 0;
}

//
// Base styles
// ----

body {
	font-family: $noto_serif;
	font-weight: normal;
	color: $black;
	line-height: $base-line-height;
	font-size: $base-font-size;
	box-sizing: border-box;
	cursor: default;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

//
// Misc
// ----

// Horizontal rule
hr {
	border: 0;
	border-bottom: 1px solid $black;
	display: block;
	height: 1px;
	margin: 0 0 $base-spacing 0;
}

// Figures
figure {
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

// Selection

::-moz-selection {
	background-color: $yellow;
}

::selection {
	background-color: $yellow;
}

// Responsive embeds

.entry-content-asset,
.embed-container {
  position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin-bottom: 20px;
}

.entry-content-asset,
.embed-container {

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}

// Screen Reader Content

.screenreader {
	border: 0 none;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}

}

// Triangle backgrounds

.bg-triangle {

	&--intro {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 0;
	    height: 0;
	    padding-left: 68%;
	    padding-top: 68%;
	    overflow: hidden;

		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			margin-left: -2000px;
			margin-top: -2000px;
			border-left: 2000px solid transparent;
			border-top: 2000px solid $yellow;
		}

	}

	&--left {
		width: 0;
	    height: 0;
	    padding-top: 30%;
	    padding-bottom: 30%;
	    padding-left: 30%;
	    overflow: hidden;
		position: absolute;
		top: 2100px;

		&:after {
		    content: "";
		    display: block;
		    width: 0;
		    height: 0;
		    margin-top: -1500px;
		    margin-left: -1500px;
		    border-top: 1500px solid transparent;
		    border-bottom: 1500px solid transparent;
		    border-left: 1500px solid $green;
		}

	}

	&--left-2 {
		width: 0;
		height: 0;
		padding-top: 15%;
		padding-bottom: 15%;
		padding-left: 15%;
		overflow: hidden;
		position: absolute;
		top: 7000px;

		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			margin-top: -500px;
			margin-left: -500px;
			border-top: 500px solid transparent;
			border-bottom: 500px solid transparent;
			border-left: 500px solid $green;
		}

	}

	&--right {
		width: 0;
	    height: 0;
	    padding-top: 30%;
	    padding-bottom: 30%;
	    padding-right: 30%;
	    overflow: hidden;
		position: absolute;
		right: 0;
		top: 4200px;

		&:after {
		    content: "";
		    display: block;
		    width: 0;
		    height: 0;
		    margin-top: -1500px;
		    margin-right: -1500px;
		    border-top: 1500px solid transparent;
		    border-bottom: 1500px solid transparent;
		    border-right: 1500px solid $yellow;
		}

	}

}
