//
// Animations
// =============================================================================

@keyframes noise-anim{

	$steps: 20;

	@for $i from 0 through $steps {

		#{percentage($i*(1/$steps))} {
			clip:rect(random(100)+px,9999px,random(100)+px,0);
		}

	}

}

@keyframes noise-anim-2 {

	$steps: 20;

	@for $i from 0 through $steps {

		#{percentage($i*(1/$steps))} {
			clip:rect(random(100)+px,9999px,random(100)+px,0);
		}

	}

}

.js .js-hide {
	opacity: 0;
}
