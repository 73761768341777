//
// Headings
// =============================================================================

//
// Variables
// ---------

$h1-size: 48px;
$h2-size: 36px;
$h3-size: 22px;
$h4-size: 18px;
$h5-size: 16px;

//
// Styles
// ------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	color: $black;
	margin: 0;
	padding: 0;
	font-family: $intro;
	font-weight: normal;
	letter-spacing: 1px;
	line-height: 1.2;
	-webkit-font-smoothing: antialiased;
}

h1, .h1 {
	margin-bottom: $base-spacing;
}

h2, h3, .h2, .h3 {
	margin-bottom: 15px;
}

h4, h5, h6, .h4, .h5, .h6 {
	margin-bottom: 10px;
}

h1, .h1 {
	font-size: 30px;

	@include breakpoint($bp-extra-small) {
		font-size: 36px;
	}

	@include breakpoint($bp-large) {
		font-size: $h1-size;
	}

}

h2, .h2 {
	font-size: $h2-size;

	@include breakpoint($bp-medium) {
		font-size: $h2-size;
	}

}

h3, .h3 {
	font-size: $h3-size;

	@include breakpoint($bp-medium) {
		font-size: $h3-size;
	}

}

h4, .h4 {
	font-size: $h4-size;
}
