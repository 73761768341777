/* ===========================
   @testimonials
   =========================== */

.testimonials {
	position: relative;
	margin-bottom: 40px;

	@include breakpoint($bp-small) {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		margin-bottom: 120px;
	}

	.review {
		text-align: center;
		position: relative;
		margin-bottom: 80px;

		@include breakpoint($bp-extra-small) {
			flex: 1;
			padding: 0 18px;
			margin-bottom: 0;
		}

		&__wrap {
			margin: 0 auto;
			display: block;
			background-color: $yellow;
			padding: 40px 30px 95px 30px;
			position: relative;
			min-height: 100%;

			@include breakpoint($bp-extra-small) {
				padding: 60px 40px 95px 40px;
				max-width: 460px;
			}

			::-moz-selection {
				background-color: $green;
			}

			::selection {
				background-color: $green;
			}

			&:before {
				content: "";
				width: 30px;
				height: 24px;
				background: url('../images/ico-quote.svg');
				background-size: 30px 24px;
				display: inline-block;
				margin-bottom: $base-spacing;
			}

		}

		&__quote {
			font-family: $intro;
			font-size: 18px;
			letter-spacing: 0;
			line-height: 1.4;
			margin-bottom: $base-spacing;

			@include breakpoint($bp-large) {
				font-size: 22px;
			}

		}

		&__name {
			font-family: $sign_painter;
			font-size: 24px;
			letter-spacing: 0;
			margin: 0;

			@include breakpoint($bp-extra-small) {
				font-size: 30px;
			}

		}

		&__company {
			font-size: 11px;
			letter-spacing: 0;
			font-family: $intro;

			@include breakpoint($bp-extra-small) {
				font-size: 12px;
			}

		}

		&__avatar {
			position: absolute;
			bottom: -30px;
			left: 50%;
			z-index: 2;
			transform: translateX(-50%);

			img {
				display: block;
			}

		}

		&--alt {

			.review {

				&__wrap {
					background-color: $green;

					::-moz-selection {
						background-color: $yellow;
					}

					::selection {
						background-color: $yellow;
					}

				}

			}

		}

	}

}
