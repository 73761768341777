/* ===========================
   @portfolio
   =========================== */

.portfolio {
	margin: 0 0 40px 0;

	@include breakpoint($bp-small) {
		margin-bottom: 40px;
	}

	@include breakpoint($bp-large) {
		padding-top: 20px;
		padding-bottom: 20px;
	}

}
