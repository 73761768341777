/* ===========================
   @footer
   =========================== */

.footer {
	background-color: $black;
	color: white;
	padding-bottom: 0;

	@include breakpoint($bp-extra-large) {
		padding-bottom: 200px; /* 120 + 80 */
	}

	&:after {
		display: none;
	}

	&__wrap {
		padding-left: 18px;
		padding-right: 18px;
		margin: 0 auto 50px auto;
		max-width: $max-width;
		position: relative;
		z-index: 2;

		@include breakpoint($bp-small) {
			margin: 0 auto;
		}

	}

	.section__title {
		color: white;

		h2 {
			color: white;
		}

	}

	.section__chapter {

		&-inner {

			&:before {
				border-top: 10px solid white;
			}

		}

		&-label {
			border-left: 1px solid white;
		}

	}

	&__form {
		max-width: 640px;
		display: block;
		margin: 0 auto 0 auto;
		position: relative;
		z-index: 2;

		@include breakpoint($bp-small) {
			margin-bottom: 320px;
		}

		@include breakpoint($bp-extra-large) {
			max-width: 50%;
			margin-bottom: 0;
		}

	}

	&__social {
		text-align: center;

		@include breakpoint($bp-extra-small) {
			text-align: left;
		}

		h4 {
			color: white;
			font-size: 15px;
			letter-spacing: 0.5px;
			margin-bottom: 14px;
		}

	}

	&__icons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		@include breakpoint($bp-extra-small) {
			justify-content: flex-start;
		}

		a {
			font-size: 22px;
			margin: 0 10px;
			display: block;
			line-height: 1.0;

			@include breakpoint($bp-extra-small) {
				margin: 0 20px 0 0;
			}

			&:hover {
				color: $green;
			}

			i:before {
				width: auto;
				margin: 0;
			}

		}

	}

	&__map {
		width: 100%;
		height: 320px;
		background-image: url('../images/bg-map-small@2x.png?size=1200');
		background-position: center top;
		background-size: 576px 320px;
		position: relative;
		display: block;
		z-index: 1;

		@include breakpoint($bp-small) {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 700px;
			height: 600px;
			background-size: 700px 600px;
			background-image: url('../images/bg-map@2x.png?size=1400');
		}

	}

	&__tooltip {
		position: absolute;
		z-index: 10;
		bottom: 115px;
		left: 50%;
		transform: translateX(-50%);
		color: $black;
		background: $yellow;
		width: 180px;

		@include breakpoint($bp-small) {
			left: auto;
			bottom: 180px;
			right: 122px;
			transform: translateX(0);
		}

		&:before {
			content: "";
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid $yellow;
			position: absolute;
			bottom: -10px;
			left: 50%;
			transform: translateX(-50%);
		}

		.tooltip__image {
			width: 100%;

			img {
				max-width: 100%;
				height: auto;
				display: block;
			}

		}

		.tooltip__text {
			padding: 14px;
			font-family: $intro;
			font-size: 11px;
    		line-height: 15px;

			a {
				color: $black;
				text-decoration: underline;
				font-family: $noto_serif;

				&:hover {
					opacity: 0.7;
				}

			}

		}

	}

}
