/* ===========================
   @Font-face
   =========================== */

@font-face {
    font-family: 'Intro';
    src: url('../fonts/intro-webfont.eot');
    src: url('../fonts/intro-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/intro-webfont.woff2') format('woff2'),
         url('../fonts/intro-webfont.woff') format('woff'),
         url('../fonts/intro-webfont.ttf') format('truetype'),
         url('../fonts/intro-webfont.svg#intro_regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sign Painter';
    src: url('../fonts/signpainter-housescript-webfont.eot');
    src: url('../fonts/signpainter-housescript-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/signpainter-housescript-webfont.woff2') format('woff2'),
         url('../fonts/signpainter-housescript-webfont.woff') format('woff'),
         url('../fonts/signpainter-housescript-webfont.ttf') format('truetype'),
         url('../fonts/signpainter-housescript-webfont.svg#signpaintermedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/NotoSerif-Regular-webfont.eot');
    src: url('../fonts/NotoSerif-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/NotoSerif-Regular-webfont.woff') format('woff'),
         url('../fonts/NotoSerif-Regular-webfont.ttf') format('truetype'),
         url('../fonts/NotoSerif-Regular-webfont.svg#noto_serifregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/NotoSerif-Bold-webfont.eot');
    src: url('../fonts/NotoSerif-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/NotoSerif-Bold-webfont.woff') format('woff'),
         url('../fonts/NotoSerif-Bold-webfont.ttf') format('truetype'),
         url('../fonts/NotoSerif-Bold-webfont.svg#noto_serifbold') format('svg');
    font-weight: bold;
    font-style: normal;
}