/* ===========================
   @usps
   =========================== */

$gutter_sm: 18px;
$gutter: 32px;

.usps {
	margin-bottom: $gutter_sm;

	@include breakpoint($bp-small) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: stretch;
		margin: (-$gutter_sm) 0 0 (-$gutter_sm);
	}

	@include breakpoint($bp-large) {
		margin: (-$gutter) 0 0 (-$gutter);
	}

	.usp {
		background-color: $yellow;
		margin-bottom: $base-spacing;
		width: 100%;

		@include breakpoint($bp-small) {
			width: calc(50% - #{$gutter_sm});
			margin: $gutter_sm 0 0 $gutter_sm;
		}

		@include breakpoint($bp-large) {
			width: calc(25% - #{$gutter});
			margin: $gutter 0 0 $gutter;
		}

		&__inner {
			padding: 30px;
			text-align: center;
			font-size: 14px;

			::-moz-selection {
				background-color: $green;
			}

			::selection {
				background-color: $green;
			}

			h3 {

				@include breakpoint($bp-large) {
					font-size: 18px;
					letter-spacing: 0.5px;
				}

				@include breakpoint($bp-extra-large) {
					font-size: 22px;
					letter-spacing: 1px;
				}

			}

		}

		&__image {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 200px;
			width: 100%;
		}

	}

}
