//
// Globals
// =============================================================================

$max-width:             1200px;

$bp-extra-small: 		480px;			// Extra small screen / phone
$bp-small: 				576px;			// Small screen / phone
$bp-medium: 			768px;			// Medium screen / tablet
$bp-large: 				992px;			// Large screen / desktop
$bp-extra-large: 		1170px;			// Extra large screen / wide desktop

// Font size for small devices
$base-font-size:        15px;
$base-line-height:      1.5;
$base-spacing:          40px;

// Font families
$font-primary:          'Noto Serif', "Georgia", sans-serif;
$font-secondary:        "Intro", "Arial", sans-serif;

$noto_serif:			$font-primary;
$intro:					$font-secondary;
$sign_painter:			'Sign Painter', sans-serif;

// Transition duration
$transition-duration:   250ms;
$tempo:   				$transition-duration;

// Colors
$color-primary:         #ffff00;
$color-secondary:       #17ff7a;
$black:                 #000000;
$red:				    #c84945;
$yellow:         		$color-primary;
$green:         		$color-secondary;
