//
// Type Styles
// =============================================================================

//
// Defaults
// --------

i,
em,
.italic {
	font-style: italic;
}

b,
strong,
.bold {
	font-weight: bold;
}

small,
.small {
	font-size: 75%;
	vertical-align: text-bottom;
}


//
// Paragraphs
// --------

p:not(:last-child) {
	margin-bottom: $base-spacing;
}


//
// Links
// -----

a {
	background-color: transparent;
	color: $yellow;
	cursor: pointer;
	text-decoration: none;
	transition: $transition-duration;

	&:hover,
	&:focus {
		color: $green;
	}

}


//
// Lists
// --------

ul,
ol {
	padding-left: $base-spacing;
	margin-bottom: $base-spacing;

	ul, ol {
		margin-bottom: 0;
	}

}

ul {

	ul {
		list-style-type: square;
	}

	ol {
		list-style-type: lower-roman;
	}

}

ol {

	ol {
		list-style-type: lower-roman;
	}

	ul {
		list-style-type: square;
	}

}
