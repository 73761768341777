/* ===========================
   @logos
   =========================== */

.logos {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	.logo {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		flex-basis: 33.333333333%;

		@include breakpoint($bp-small) {
			flex-basis: 25%;
		}

		@include breakpoint($bp-medium) {
			flex-basis: 16.666666667%;
		}

		img {
			max-width: 100%;
			height: auto;
			opacity: 0.5;
			display: inline-block;
			transition: opacity $tempo ease;

			@include breakpoint($bp-small) {
				max-width: 100%;
			}

		}

		a {
			display: block;
			text-align: center;
			padding: 10px;

			@include breakpoint($bp-small) {
				padding: 20px;
			}

			&:hover {

				img {
					opacity: 1;
				}

			}

		}

		&--cc {

			img {
				max-width: 50px;

				@include breakpoint($bp-large) {
					max-width: 100%;
				}

			}

		}

		&--marvel {

			img {
				max-width: 70px;

				@include breakpoint($bp-large) {
					max-width: 100%;
				}

			}

		}

	}

}
