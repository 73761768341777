/* ===========================
   @team
   =========================== */

.team {
	display: block;

	.member {
		position: relative;

		&__image {
			display: block;

			img {
				display: block;
				width: 100%;
				height: auto;
			}

		}

		&__info {
			width: 100%;
			background-color: $yellow;
			padding: 40px;
			font-size: 14px;

			@include breakpoint($bp-extra-small) {
				font-size: 15px;
			}

			::-moz-selection {
				background-color: $green;
			}

			::selection {
				background-color: $green;
			}

			@include breakpoint($bp-large) {
				width: 420px;
				position: absolute;
				bottom: -60px;
				left: 80px;
			}

		}

		&__avatar {
			display: block;
			text-align: center;
			margin-bottom: 20px;

			img {
				display: inline-block;
				max-width: 60px;

				@include breakpoint($bp-extra-small) {
					max-width: 98px;
				}

				@include breakpoint($bp-large) {
					max-width: 60px;
				}

				@include breakpoint($bp-extra-large) {
					max-width: 98px;
				}

			}

		}

		&__name,
		&__title {
			text-align: center;
			display: block;
		}

		&__name {
			font-size: 22px;
			letter-spacing: 0;
			margin: 0;

			@include breakpoint($bp-extra-small) {
				font-size: 28px;
				letter-spacing: -1px;
			}

		}

		&__title {
			font-family: $sign_painter;
			font-weight: normal;
			font-size: 24px;
			line-height: 1.4;
			display: block;
			margin-bottom: 18px;

			@include breakpoint($bp-extra-small) {
				font-size: 28px;
			}

		}

		&__links {

			@include breakpoint($bp-extra-small) {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

		}

		&__social {

			@include breakpoint($bp-extra-small) {
				flex: 1;
				text-align: right;
			}

			a {
				display: inline-block;
				color: $black;
				line-height: 1.0;
				margin-right: 8px;
				font-size: 18px;

				@include breakpoint($bp-extra-small) {
					margin-right: 0;
					margin-left: 8px;
				}

			}

		}

		&__website {
			margin-bottom: 20px;

			@include breakpoint($bp-extra-small) {
				margin-bottom: 0;
			}

		}

		&--align-right {

			.member {

				&__info {
					left: auto;
					right: 80px;
					background-color: $green;

					::-moz-selection {
						background-color: $yellow;
					}

					::selection {
						background-color: $yellow;
					}

				}

			}

		}

	}

	.member-spacer {
		width: 100%;
		height: 150px;
		position: relative;

		@include breakpoint($bp-extra-small) {
			height: 200px;
		}

		@include breakpoint($bp-large) {
			height: 300px;
		}

		@include breakpoint($bp-extra-large) {
			height: 200px;
		}

		&:before {
			content: "";
			position: absolute;
			top: 30px;
			left: 50%;
			bottom: 30px;
			width: 1px;
			background-color: $black;

			@include breakpoint($bp-extra-small) {
				top: 40px;
				bottom: 40px;
			}

			@include breakpoint($bp-large) {
				top: 120px;
				bottom: 60px;
			}

			@include breakpoint($bp-extra-large) {
				top: 40px;
				bottom: 40px;
			}

		}

	}

}
