/* ===========================
   @section
   =========================== */

.section {
	padding-top: 60px;
	padding-bottom: 140px;
	position: relative;
	overflow-x: hidden;

	@include breakpoint($bp-large) {
		padding-top: 80px;
		padding-bottom: 200px;
	}

	&--timeline {
		padding-bottom: 80px;

		@include breakpoint($bp-large) {
			padding-bottom: 100px;
		}

	}

	&:after {
		content: "";
		position: absolute;
		z-index: 4;
		bottom: 0;
		left: 50%;
		width: 1px;
		height: 80px;
		background-color: $black;

		@include breakpoint($bp-large) {
			height: 120px;
		}

	}

	&__wrap {
		padding-left: 18px;
		padding-right: 18px;
		margin: 0 auto;
		max-width: $max-width;
		position: relative;
		z-index: 5;
	}

	&__chapter {
		margin: 0 auto 48px auto;
		text-align: center;
		overflow: hidden;
		height: 100px;
		z-index: 10;
		position: relative;

		&-inner {
			width: auto;
			display: inline-block;
			position: relative;

			&:before {
				content: "";
				width: 0;
				height: 0;
				border-top: 10px solid $black;
				border-left: 10px solid transparent;
				border-bottom: 10px solid transparent;
				position: absolute;
				top: 0px;
				left: -35px;
			}

			strong {
				font-size: 120px;
				font-weight: normal;
				line-height: 90px;
				height: 100px;
				display: block;
				position: relative;
			}

		}

		&-label {
			position: absolute;
			top: 40px;
			left: -50px;
			display: block;
			font-size: 14px;
			border-left: 1px solid $black;
			padding-left: 10px;
			line-height: 18px;
			height: 20px;
			transform: rotate(-90deg);
		}

	}

	&__title {
		text-align: center;
		margin-bottom: 100px;
		position: relative;

		h2 {
			font-size: 30px;

			@include breakpoint($bp-extra-small) {
				font-size: 36px;
			}

		}

		&:after {
			content: "";
			width: 38px;
			height: 16px;
			background: url('../images/ico-spacer.svg');
			background-size: 38px 16px;
			position: absolute;
			bottom: -40px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1;
		}

		&--alt {

			&:after {
				background-image: url('../images/ico-spacer-green.svg');
			}

		}

	}

	&__subtitle {
		text-align: center;

		h3 {
			font-size: 15px;
			letter-spacing: 0.5px;

			@include breakpoint($bp-extra-small) {
				font-size: 18px;
				letter-spacing: 0.5px;
			}

		}

	}

	&__intro {
		max-width: 540px;
		font-size: 15px;
		display: block;
		margin: 0 auto 40px auto;
		text-align: center;

		@include breakpoint($bp-extra-small) {
			font-size: 16px;
		}

		@include breakpoint($bp-large) {
			max-width: 700px;
			font-size: 18px;
		}

		&--work {
			max-width: 640px;
		}

	}

	&__cta {
		text-align: center;
		position: relative;
		z-index: 2;
	}

	&__partners {
		padding-top: 80px;

		@include breakpoint($bp-large) {
			padding-top: 120px;
		}

	}

}
