/* ===========================
   @header
   =========================== */

.header {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: $black;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 200;

	@include breakpoint($bp-large) {
		position: absolute;
		padding-top: 30px;
		padding-bottom: 30px;
		background-color: transparent;
	}

	&__wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		max-width: $max-width;
		padding-left: 15px;
		padding-right: 15px;
		margin: 0 auto;
	}

	&__logo {
		display: block;
		width: 80px;
		flex-grow: 1;
		flex-shrink: 0;
		margin-right: 40px;

		@include breakpoint($bp-large) {
			width: 100px;
			flex-grow: 0;
			margin-right: 0;
		}

		.logo-type {
			display: block;

			@include breakpoint($bp-large) {
				display: none;
			}

		}

		.logo-full {
			display: none;

			@include breakpoint($bp-large) {
				display: block;
			}

		}

		img {
			display: block;
			width: 100%;
			max-width: 80px;
			height: auto;

			@include breakpoint($bp-medium) {
				max-width: 100px;
			}

		}

	}

	&__nav {
		flex: 1;
		display: none;

		@include breakpoint($bp-large) {
			display: block;
		}

		ul {
			display: none;

			@include breakpoint($bp-large) {
				display: block;
				margin: 0;
				padding: 0;
				width: 100%;
				text-align: center;
			}

			li {
				margin: 0 18px;
				padding: 0;
				list-style: none;
				display: inline-block;

				@include breakpoint($bp-extra-large) {
					margin: 0 20px;
				}

				a {
					color: $black;
					font-family: $intro;
					font-size: 14px;
					font-weight: normal;
					display: block;
					transition: none;

					@include breakpoint($bp-extra-large) {
						font-size: 15px;
					}

				}

			}

		}

	}

	&__nav-toggle {
		appearance: none;
		background: none;
		outline: 0;
		border: 0;
		box-shadow: 0;
		color: white;
		font-family: $font-secondary;
		font-size: 16px;
		line-height: 1.0;
		padding-top: 2px;
		padding-bottom: 2px;
		order: 3;
		cursor: pointer;
		display: block;
		position: relative;
		border-bottom: 1px solid white;

		@include breakpoint($bp-large) {
			display: none;
		}

		&:before {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			top: 4px;
			left: -20px;
			border-top: 12px solid $yellow;
			border-left: 12px solid transparent;
			border-bottom: 12px solid transparent;
			transition: all $tempo ease;
		}

		&--active {
			&:before {
				transform: rotate(-225deg);
				top: -10px;
				left: -24px;
			}
		}
	}

	&--fixed {
		padding-top: 10px;
		padding-bottom: 10px;
		background-color: $black;
		position: fixed;

		.header {

			&__logo {
				order: 1;
				flex-grow: 1;
				flex-shrink: 0;
				margin-right: 40px;

				.logo-full {
					display: none;
				}

				.logo-type {
					display: block;
				}

			}

			&__nav {
				order: 2;
				flex: none;

				ul {
					text-align: left;

					li {
						margin: 0 0 0 30px;

						@include breakpoint($bp-extra-large) {
							margin: 0 0 0 40px;
						}

						a {
							color: white;

							&:hover {
								color: $yellow;
							}

						}

						&.active {

							> a {
								color: $yellow;
							}

						}

					}

				}

			}

		}

	}

}
